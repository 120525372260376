function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export default {
  props: {
    config: {
      type: Object,
      default: function _default() {
        return {
          multiple: false,
          // 是否多选
          columns: [],
          // 表头配置
          getDataFun: function getDataFun() {},
          // 数据请求接口
          defaultParams: {},
          // 默认参数
          commonParams: {},
          // 公共参数
          initLoad: true,
          // 初始完成是否自动加载列表
          btns: [] // 底部操作按钮
        };
      }
    }
  },

  components: {
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null
        }
      },
      render: function render(h, ctx) {
        var params = {
          row: ctx.props.row,
          index: ctx.props.index
        };
        if (ctx.props.column) params.column = ctx.props.column;
        return ctx.props.render(params.row, params.index);
      }
    }
  },
  mounted: function mounted() {
    var _this$config = this.config,
      commonParams = _this$config.commonParams,
      defaultParams = _this$config.defaultParams,
      _this$config$initLoad = _this$config.initLoad,
      initLoad = _this$config$initLoad === void 0 ? true : _this$config$initLoad;
    var tableFilterDom = document.getElementsByClassName('table-filter')[0];
    this.tableFilterHeight = tableFilterDom && tableFilterDom.offsetHeight;
    this.commonParams = _objectSpread({}, commonParams);
    if (initLoad) {
      this.initTable(defaultParams);
    }
  },
  data: function data() {
    return {
      selectData: [],
      tableFilterHeight: 0,
      data: [],
      commonParams: {},
      searchParam: {},
      sortParams: {},
      pageInfo: {
        page: 1,
        ps: 20,
        total: 1000
      },
      loading: false
    };
  },
  methods: {
    selectChange: function selectChange(row) {
      this.selectData = row;
      this.$emit('selectChange', row); // 给出checkbox选中数据列表
    },
    sortChange: function sortChange(_ref) {
      var column = _ref.column,
        prop = _ref.prop,
        order = _ref.order;
      // 如果prop值中包含#，说明排序字段是个对象，使用#后面的值来当做key
      var propParams = prop;
      var existIndex = prop.indexOf('#');
      if (existIndex > -1) {
        propParams = prop.substr(-(prop.length - existIndex) + 1);
      }
      // order为空时 则为取消排序
      var sortObj = {};
      // 升序
      if (order === 'ascending') {
        sortObj.order_by = "".concat(propParams || 'time', "#asc");
      }
      // 降序
      if (order === 'descending') {
        sortObj.order_by = "".concat(propParams || 'time', "#desc");
      }
      this.sortParams = sortObj;
      this.pageInfo.page = 1;
      this.getData();
    },
    initTable: function initTable(param) {
      // 初始表参数（页数信息）
      this.pageInfo.page = 1;
      this.searchParam = param; // 存搜索条件
      this.getData();
    },
    getData: function getData() {
      var _this = this;
      var params = _objectSpread(_objectSpread(_objectSpread({
        page: this.pageInfo
      }, this.commonParams), this.searchParam), this.sortParams);
      this.loading = true;
      this.config.getDataFun(params).then(function (res) {
        var _ref2 = (res === null || res === void 0 ? void 0 : res.data) || {
            items: []
          },
          items = _ref2.items,
          Items = _ref2.Items,
          total = _ref2.total;
        if (!items && !Items && _this.pageInfo.page > 0) {
          // this.pageInfo.page++;
          // this.getData()
          return;
        }
        _this.data = items || Items;
        _this.pageInfo.total = total;
        _this.loading = false;
      }).finally(function () {
        _this.loading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.pageInfo.page = page;
      this.getData();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.pageInfo.ps = val;
      this.pageInfo.page = 1;
      this.getData();
    },
    getListData: function getListData(key) {
      return key ? this.data.map(function (item) {
        return item[key];
      }) : this.data;
    },
    handleCommand: function handleCommand(key) {
      var btn = this.config.btns.find(function (item) {
        return item.key === key;
      });
      btn.click(this.selectData, key);
    }
  }
};