import ApiMenu from './components/ApiMenu';
import { API_URL } from '@/utils/common';
export default {
  components: {
    ApiMenu: ApiMenu
  },
  data: function data() {
    return {
      API_URL: API_URL
    };
  },
  computed: {},
  methods: {}
};