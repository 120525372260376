import fetch from '@/utils/fetch';

/*
打点数据上报
*/
export function dotReport(data) {
  return fetch({
    url: '/api/v1/dot/report',
    method: 'post',
    data: data
  });
}
/*
举报作品
*/
export function reportArtwork(data) {
  return fetch({
    url: '/api/v1/report/artwork',
    method: 'post',
    data: data,
    customConfig: {
      isSilent: true
    }
  });
}
/*
 *获取热门标签
 */
export function getTagHot(data) {
  return fetch({
    url: '/api/v1/tag/hot',
    method: 'post',
    data: data
  });
}
/*
 *浏览器消息推送 上传设备token
 */
export function setDeviceToken(data) {
  return fetch({
    url: '/api/v1/msg/token/update',
    method: 'post',
    data: data,
    customConfig: {
      isSilent: true
    }
  });
}
/*
 *通知公告最新一条
 */
export function getNotice(data) {
  return fetch({
    url: '/api/v1/announcement/fetch',
    method: 'post',
    data: data,
    customConfig: {
      isSilent: true
    }
  });
}
// 通知公告历史消息
export function getHistoryNoticeList(data) {
  return fetch({
    url: '/api/v1/announcement/fetch-list',
    method: 'post',
    data: data,
    customConfig: {
      isSilent: true
    }
  });
}
/*
 *已读公告
 */
export function setNotice(data) {
  return fetch({
    // url: '/api/v1/setNotice',
    url: '/api/v1/announcement/fetch',
    method: 'post',
    data: data,
    customConfig: {
      isSilent: true
    }
  });
}

/**
 * 获取任务相关配置
 * */
export function getTaskConfigOptions() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return fetch({
    url: '/api/v1/task/config',
    method: 'POST',
    data: data
  });
}

/**
 * 预签名上传接口--获取上传信息
 * */
export function getPreSignInfo(data) {
  return fetch({
    url: '/api/v1/resource/uploadImageByPreSign',
    method: 'POST',
    data: data
  });
}

/**
 * 预签名上传接口--确认上传完成
 * */
export function confirmPreSignFile(data) {
  return fetch({
    url: '/api/v1/resource/confirmImageUploadedByPreSign',
    method: 'POST',
    data: data
  });
}

/**
 * 批量进度查询
 * */
export function batchFetchTaskProcess(data) {
  return fetch({
    url: '/api/v1/task/batch-progress',
    method: 'POST',
    data: data
  });
}

/**
 * 创建任务
 * */
export function createTask(data) {
  return fetch({
    url: '/api/v1/task/create',
    method: 'POST',
    data: data
  });
}

/**
tags列表获取
*/
export function getTagsList(data) {
  return fetch({
    url: '/api/v1/recommend/hotTag',
    method: 'post',
    data: data
  });
}