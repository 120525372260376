function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
export default [{
  path: '/',
  name: 'Home',
  meta: {
    icon: 'el-icon-s-home'
  },
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/home/index.vue'));
    });
  }
}, {
  path: '/account',
  name: 'AcconutManage',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/account/index.vue'));
    });
  },
  meta: {
    icon: 'el-icon-user'
  }
}, {
  path: '/analysis',
  name: 'Analysis',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/analysis/index.vue'));
    });
  },
  meta: {
    icon: 'haiyi haiyi-shujufenxi'
  }
}, {
  path: '/API',
  name: 'APIInfo',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/api/index.vue'));
    });
  },
  meta: {
    icon: 'haiyi haiyi-api'
  }
}, {
  path: '/config',
  name: 'Config',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/devConfig/index.vue'));
    });
  },
  meta: {
    icon: 'haiyi haiyi-peizhi'
  }
}, {
  path: '/renewalPackage',
  name: 'RenewalPackage',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/renewalPackage/index.vue'));
    });
  },
  meta: {
    icon: 'haiyi haiyi-xufei'
  }
}, {
  path: '/purchase',
  name: 'Purchase',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/purchase/index.vue'));
    });
  },
  meta: {
    icon: 'haiyi haiyi-goumailishi'
  },
  redirect: '/purchase/history',
  children: [{
    path: 'history',
    name: 'PurchaseHistory',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/view/purchase/history.vue'));
      });
    },
    meta: {}
  }
  // {
  //   path: 'rule',
  //   name: '购买规则',
  //   component: () => import('@/view/purchase/rule.vue'),
  //   meta: { tips: 'API服务同样享有企业版海艺权限及服务' }
  // }
  ]
}, {
  path: '/login',
  name: 'Login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/login/index.vue'));
    });
  },
  meta: {
    full: true,
    public: true
  }
}, {
  path: '/403',
  name: '403',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/403/index.vue'));
    });
  },
  meta: {
    full: true,
    public: true
  }
}, {
  path: '/404',
  name: '404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/view/404/index.vue'));
    });
  },
  meta: {
    full: true,
    public: true
  }
}, {
  path: '*',
  name: '*',
  redirect: '/404',
  // redirect: '/login', // 封测期间，暂时跳转到登录
  meta: {
    full: true
  }
}];