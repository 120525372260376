var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isShow ? _c("div", {
    staticClass: "view-image-popup"
  }, [_c("el-image-viewer", {
    staticClass: "message-img-viewer",
    staticStyle: {
      "z-index": "9999"
    },
    attrs: {
      "on-close": _vm.closeViewBigImage,
      "url-list": _vm.imageList,
      "initial-index": _vm.showIndex
    }
  })], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };