var render = function render() {
  var _vm$goodsInfo, _vm$goodsInfo2;
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "haiyi-payment-method-dialog custom-dialog",
    attrs: {
      visible: _vm.isShowDialog,
      "destroy-on-close": true,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isShowDialog = $event;
      },
      closed: _vm.closed
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_c("i", {
    staticClass: "el-icon-close close-icon",
    on: {
      click: function click($event) {
        _vm.isShowDialog = false;
      }
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("Renewal31")))]), _vm._v(" "), _c("div", {
    staticClass: "goods-name row"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("Renewal32")) + ":")]), _vm._v(" "), _c("span", {
    staticClass: "value"
  }, [_vm.type ? _c("span", [_vm._v("\n          " + _vm._s(_vm.type) + "\n        ")]) : _c("span", [_vm._v("\n          no type\n        ")])]), _vm._v(" "), _c("span", {
    staticClass: "number"
  }, [_vm._v("*" + _vm._s((_vm$goodsInfo = _vm.goodsInfo) === null || _vm$goodsInfo === void 0 ? void 0 : _vm$goodsInfo.numbers))])]), _vm._v(" "), _c("div", {
    staticClass: "goods-price row"
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("Renewal33")) + ":")]), _vm._v(" "), _c("span", {
    staticClass: "value"
  }, [_vm._v("\n        " + _vm._s("".concat(_vm.$CHINA ? "￥" : "$", " ").concat((_vm$goodsInfo2 = _vm.goodsInfo) === null || _vm$goodsInfo2 === void 0 ? void 0 : _vm$goodsInfo2.price)) + "\n      ")])]), _vm._v(" "), _c("div", {
    staticClass: "payment-method row"
  }, [_c("div", {
    staticClass: "up w-full"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("Renewal34")))])]), _vm._v(" "), _c("div", {
    staticClass: "down w-full"
  }, _vm._l(_vm.payMethods, function (pay, index) {
    return _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$CHINA ? index < 2 : index > 1,
        expression: "$CHINA ? index < 2 : index > 1"
      }],
      key: "payMethods_" + pay.type,
      staticClass: "pay-item"
    }, [_c("div", {
      staticClass: "box"
    }, [_c("img", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.qrCode || pay.type !== 5,
        expression: "!qrCode || pay.type !== 5"
      }],
      staticClass: "icon",
      attrs: {
        src: require("@/assets/images/" + pay.icon),
        alt: "alipay-icon"
      }
    }), _vm._v(" "), pay.type === 5 ? _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.qrCode,
        expression: "qrCode"
      }],
      ref: "QRCodeContainer",
      refInFor: true,
      staticClass: "QR-code div"
    }) : _vm._e()]), _vm._v(" "), _c("el-button", {
      staticClass: "btn",
      style: _vm.getBtnStyle,
      attrs: {
        loading: pay.loading
      },
      on: {
        click: function click($event) {
          return _vm.createOrder(pay.type);
        }
      }
    }, [_vm._v("\n            " + _vm._s(_vm.$t(pay.title)) + "\n            "), _c("i", {
      staticClass: "el-icon-arrow-right"
    })])], 1);
  }), 0)])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };