var render = function render() {
  var _vm$user;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-head",
    style: {
      lineHeight: _vm.size + "px"
    }
  }, [_c("div", {
    staticClass: "user-avatar",
    style: _vm.avatarStyle
  }, [_vm._v("\n    " + _vm._s((_vm$user = _vm.user) !== null && _vm$user !== void 0 && _vm$user.head ? "" : _vm.firstName) + "\n  ")]), _vm._v(" "), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideName,
      expression: "!hideName"
    }],
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.user.name || _vm.user.id))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };