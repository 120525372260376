import fetch from '@/utils/fetch';
var publicPath = '/api/v1/manager/payment/order/';

/**
获取购买历史
*/
export function getOrderList(data) {
  return fetch({
    url: publicPath + 'list',
    method: 'post',
    data: data
  });
}