export default {
  data: function data() {
    return {
      isShow: false,
      zoomIndex: [],
      faqList: [{
        q: this.$t('Renewal19'),
        a: this.$t('Renewal20')
      }, {
        q: this.$t('Renewal21'),
        a: this.$t('Renewal22')
      }, {
        q: this.$t('Renewal23'),
        a: this.$t('Renewal24')
      }, {
        q: this.$t('Renewal25'),
        a: this.$t('Renewal26')
      }, {
        q: this.$t('Renewal27'),
        a: this.$t('Renewal28')
      }, {
        q: this.$t('Renewal29'),
        a: this.$t('Renewal30')
      }]
    };
  },
  methods: {
    showDialog: function showDialog() {
      this.isShow = true;
    },
    close: function close() {
      this.isShow = false;
    },
    zoom: function zoom(index) {
      var i = this.zoomIndex.indexOf(index);
      if (i > -1) {
        this.zoomIndex.splice(i, 1);
      } else {
        this.zoomIndex.push(index);
      }
    }
  }
};