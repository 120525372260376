function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { mapState } from 'vuex';
export default {
  props: {
    size: {
      type: Number,
      default: 24
    },
    user: {
      type: Object,
      default: function _default() {
        return {
          id: '-',
          name: '',
          head: ''
        };
      }
    },
    hideName: {
      type: Boolean,
      default: false
    },
    blank: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapState('user', ['userInfo'])), {}, {
    firstName: function firstName() {
      var _ref = this.user || {},
        name = _ref.name,
        id = _ref.id;
      var first = name ? name[0] : id ? id[0] : '';
      return first.toUpperCase();
    },
    isOwner: function isOwner() {
      return this.userInfo.id === this.user.id;
    },
    avatarStyle: function avatarStyle() {
      var _this$user;
      var size = this.size + 'px';
      var head = (_this$user = this.user) === null || _this$user === void 0 ? void 0 : _this$user.head;
      return {
        width: size,
        height: size,
        lineHeight: size,
        fontSize: this.size / 2 + 'px',
        backgroundImage: 'url(' + head + ')'
      };
    }
  }),
  methods: {}
};