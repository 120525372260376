var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "analysis-page"
  }, [_c("div", {
    staticClass: "analysis-item"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("Analysis1")))]), _vm._v(" "), _c("div", {
    staticClass: "show-info"
  }, [_c("div", {
    staticClass: "info-item"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "info-time"
  }, [_vm._v(_vm._s(_vm.remainingTimeFormat(_vm.usageInfo.UsedGpu, "DHM")))]), _vm._v(" "), _c("div", {
    staticClass: "info-tips"
  }, [_vm._v(_vm._s(_vm.$t("Analysis3")) + "\n          "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm.$t("Analysis4"),
      placement: "top-start"
    }
  }, [_c("i", {
    staticClass: "el-icon-info"
  })])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "info-item"
  }, [_vm._m(1), _vm._v(" "), _c("div", {
    staticClass: "info-time"
  }, [_vm._v(_vm._s(_vm._f("BToG")(_vm.usageInfo.UsedStorage)))]), _vm._v(" "), _c("div", {
    staticClass: "info-tips"
  }, [_vm._v(_vm._s(_vm.$t("Analysis5")) + "\n          "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm.$t("Analysis4"),
      placement: "top-start"
    }
  }, [_c("i", {
    staticClass: "el-icon-info"
  })])], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "echart-item"
  }, [_c("line-chart", {
    attrs: {
      type: "TIME"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "analysis-item"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("Analysis7")))]), _vm._v(" "), _c("div", {
    staticClass: "show-info"
  }, [_c("div", {
    staticClass: "info-item"
  }, [_vm._m(2), _vm._v(" "), _c("div", {
    staticClass: "info-time"
  }, [_vm._v(_vm._s(_vm.remainingTimeFormat(_vm.usageInfo.RemainGpu, "DH")))]), _vm._v(" "), _c("div", {
    staticClass: "info-tips"
  }, [_vm._v(_vm._s(_vm.$t("Analysis8")) + "\n          "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: _vm.$t("Analysis9"),
      placement: "top-start"
    }
  }, [_c("i", {
    staticClass: "el-icon-info"
  })])], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "echart-item"
  }, [_c("line-chart", {
    attrs: {
      type: "STORE"
    }
  })], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "info-icon"
  }, [_c("i", {
    staticClass: "haiyi haiyi-gpu"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "info-icon"
  }, [_c("i", {
    staticClass: "haiyi haiyi-yunyingpan"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "info-icon"
  }, [_c("i", {
    staticClass: "haiyi haiyi-gpu"
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };