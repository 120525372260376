export default function compressImage(imgFile, aimSize) {
  var quality = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0.9;
  return new Promise(function (resolve, reject) {
    var reader = new FileReader();
    reader.readAsDataURL(imgFile);
    reader.onload = function (event) {
      var img = new Image();
      img.src = event.target.result;
      img.onload = function () {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        var width = canvas.width;
        var height = canvas.height;
        ctx.drawImage(img, 0, 0, width, height);
        var newDataUrl = canvas.toDataURL('image/jpeg', quality);
        var newSize = Math.floor(newDataUrl.length);
        if (newSize > aimSize) {
          // 如果压缩后的图片大小仍然超过2M，则重新压缩图片
          resolve(compressImage(imgFile, aimSize, quality - 0.1));
        } else {
          // 将压缩后的图片数据生成新的File对象并返回
          var newFile = dataURLtoFile(newDataUrl, imgFile.name);
          resolve(newFile);
        }
      };
    };
  });
}

// dataURL转换为File对象的函数
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime
  });
}