var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container",
    attrs: {
      id: "app"
    }
  }, [!_vm.$route.meta.full ? _c("l-menu", {
    ref: "menu",
    attrs: {
      isCollapse: _vm.isCollapse
    }
  }) : _vm._e(), _vm._v(" "), _c("el-container", {
    staticClass: "body manage-page"
  }, [!_vm.$route.meta.full ? _c("l-header", {
    attrs: {
      isCollapse: _vm.isCollapse,
      pathList: _vm.pathList
    },
    on: {
      zoom: _vm.zoom
    }
  }) : _vm._e(), _vm._v(" "), !_vm.$route.meta.types ? _c("keep-alive", [_c("router-view")], 1) : _c("router-view", {
    key: _vm.$route.path
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };