var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "config-page"
  }, [_c("div", {
    staticClass: "client-id"
  }, [_vm._v("client_id: " + _vm._s(_vm.clientId))]), _vm._v(" "), _c("div", {
    staticClass: "secret"
  }, [_vm._v("\n    secret: " + _vm._s(_vm.isShow ? _vm.secret : "******") + "\n    "), _c("i", {
    staticClass: "icon haiyi",
    class: _vm.isShow ? "haiyi-yanjing_xianshi" : "haiyi-yanjing_yincang",
    on: {
      click: function click($event) {
        _vm.isShow = !_vm.isShow;
      }
    }
  })]), _vm._v(" "), _c("el-form", {
    ref: "form",
    staticClass: "custom-form",
    attrs: {
      model: _vm.formData,
      rules: _vm.formRules,
      "label-position": "left",
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("DevConfig1"),
      prop: "endpoint",
      required: ""
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("DevConfig12")
    },
    model: {
      value: _vm.formData.endpoint,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "endpoint", $$v);
      },
      expression: "formData.endpoint"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("DevConfig2")
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.formData.event_subscription["task.finish"],
      callback: function callback($$v) {
        _vm.$set(_vm.formData.event_subscription, "task.finish", $$v);
      },
      expression: "formData.event_subscription['task.finish']"
    }
  }, [_vm._v(_vm._s(_vm.$t("DevConfig4")))]), _vm._v(" "), _c("el-checkbox", {
    model: {
      value: _vm.formData.event_subscription["task.sys_cancel"],
      callback: function callback($$v) {
        _vm.$set(_vm.formData.event_subscription, "task.sys_cancel", $$v);
      },
      expression: "formData.event_subscription['task.sys_cancel']"
    }
  }, [_vm._v(_vm._s(_vm.$t("DevConfig5")))])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("DevConfig3"),
      prop: "secret",
      required: ""
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("DevConfig13")
    },
    model: {
      value: _vm.formData.secret,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "secret", $$v);
      },
      expression: "formData.secret"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "btn-group"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      loading: _vm.loading
    },
    on: {
      click: _vm.saveConfig
    }
  }, [_vm._v(_vm._s(_vm.$t("DevConfig6")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      plain: ""
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(_vm._s(_vm.$t("DevConfig7")))])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };