import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import user from './modules/user';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    user: user
  },
  getters: getters,
  plugins: [createPersistedState()]
});
export default store;