export default {
  data: function data() {
    return {
      apiMenu: ['更新历史', '简介', 'API概览', '调用方式', '相关接口', '数据结构', '错误码'],
      activeIndex: 0,
      apiZoom: false
    };
  },
  methods: {
    zoom: function zoom() {
      this.apiZoom = !this.apiZoom;
    }
  }
};