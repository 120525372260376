import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import axios from 'axios';
import store from '@/store';
import { setHeaders } from './setHeaders';
import { removePending, addPending } from './requestOptimization';
import router from '@/router/index';
import { ERROR_CODE, WARNING_CODE } from './common';
var locationUrl = '';
// if (window.location.origin === 'http://localhost:9528') {
//   locationUrl = process.env.VUE_APP_BASE_API;
// } else {
//   locationUrl = window.location.origin + process.env.VUE_APP_BASE_API;
// }

// create an axios instance
var service = axios.create({
  baseURL: locationUrl,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 * 6 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // 增加自定义配置 （needRemovePending:取消上一次同样的还在pending中的请求
  var _ref = config.customConfig || {},
    _ref$needRemovePendin = _ref.needRemovePending,
    needRemovePending = _ref$needRemovePendin === void 0 ? true : _ref$needRemovePendin,
    _ref$language = _ref.language,
    language = _ref$language === void 0 ? '' : _ref$language;
  // console.log('请求拦截 config', language);
  if (needRemovePending) {
    removePending(config); // 在请求开始前，对之前的请求做检查取消操作
    addPending(config); // 将当前请求添加到 pending 中
  }
  // do something before request is sent

  if (store.getters.token) {
    setHeaders(config.headers);
  }
  // 单独设置deviceId 没有token的时候也需要传deviceId
  config.headers['X-Device-Id'] = localStorage.getItem('deviceId');
  // 来自于web的请求都加上这个字段
  config.headers['X-Platform'] = 'web';
  if (language) {
    config.headers['Accept-Language'] = language;
  } else {
    var lang = localStorage.getItem('language') || navigator.language || navigator.browserLanguage;
    config.headers['Accept-Language'] = lang;
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var _response$data;
  var code = response.data.status.code,
    config = response.config;
  // 增加自定义配置 （needRemovePending:取消上一次同样的还在pending中的请求
  var _ref2 = config.customConfig || {},
    _ref2$needRemovePendi = _ref2.needRemovePending,
    needRemovePending = _ref2$needRemovePendi === void 0 ? true : _ref2$needRemovePendi;
  var ERROR_MSG = '';
  // 在请求结束后，移除本次请求
  if (needRemovePending) {
    removePending(config);
  }
  var data = (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.data;
  // 41000 检测存在错误
  if (code !== 10000) {
    ERROR_MSG = ERROR_CODE[code] || '服务器开小差啦~！';
    if (ERROR_MSG) {
      _Message({
        message: ERROR_MSG,
        type: WARNING_CODE.includes(code) ? 'warning' : 'error',
        duration: 5 * 1000
      });
    }
    return Promise.reject(response.data.status);
  } else if ([10403].includes(code)) {
    router.replace('/403');
  } else {
    // 快过期会返回token更新
    if (response.headers['token'] || response.headers['Token']) {
      var t = response.headers['token'] || response.headers['Token'];
      store.commit('user/SET_TOKEN', t);
    }
    return {
      data: data
    };
  }
}, function (error) {
  if (error.message == 'requestTooMuchRecord') {
    return Promise.reject(error);
  }
  if (axios.isCancel(error)) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve(null);
      }, 31536000000); // 设置时间 为 一年，保持既不成功，也不失败的状态
    });
  }

  console.log('onRejected:', error); // for debug
  _Message({
    message: '服务器开小差啦，请稍等一下',
    type: 'error',
    duration: 5 * 1000
  });
  // 如果是 激活页 502 错误，则尝试重试请求
  if (error.response && error.response.status === 502 && error.config.url === '/api/v1/account/active') {
    var _error$config;
    var retries = (error === null || error === void 0 ? void 0 : (_error$config = error.config) === null || _error$config === void 0 ? void 0 : _error$config.retries) || 0;
    if (retries < 3) {
      // 最多重试 3 次
      error.config.retries = retries + 1;
      return new Promise(function (resolve) {
        return setTimeout(function () {
          return resolve(service(error.config));
        }, 1000);
      });
    }
  }

  // 否则抛出错误
  console.error(error);
  return Promise.reject(error);
});
export default service;