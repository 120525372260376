import SubMenu from '@/components/SubMenu.vue';
export default {
  components: {
    SubMenu: SubMenu
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {}
};