import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import _Notification2 from "element-ui/lib/theme-chalk/notification.css";
import "element-ui/lib/theme-chalk/base.css";
import _Notification from "element-ui/lib/notification";
import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import _Step2 from "element-ui/lib/theme-chalk/step.css";
import "element-ui/lib/theme-chalk/base.css";
import _Step from "element-ui/lib/step";
import _Steps2 from "element-ui/lib/theme-chalk/steps.css";
import "element-ui/lib/theme-chalk/base.css";
import _Steps from "element-ui/lib/steps";
import _Empty2 from "element-ui/lib/theme-chalk/empty.css";
import "element-ui/lib/theme-chalk/base.css";
import _Empty from "element-ui/lib/empty";
import _CarouselItem2 from "element-ui/lib/theme-chalk/carousel-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _CarouselItem from "element-ui/lib/carousel-item";
import _Carousel2 from "element-ui/lib/theme-chalk/carousel.css";
import "element-ui/lib/theme-chalk/base.css";
import _Carousel from "element-ui/lib/carousel";
import _Drawer2 from "element-ui/lib/theme-chalk/drawer.css";
import "element-ui/lib/theme-chalk/base.css";
import _Drawer from "element-ui/lib/drawer";
import _InfiniteScroll2 from "element-ui/lib/theme-chalk/infinite-scroll.css";
import "element-ui/lib/theme-chalk/base.css";
import _InfiniteScroll from "element-ui/lib/infinite-scroll";
import _Badge2 from "element-ui/lib/theme-chalk/badge.css";
import "element-ui/lib/theme-chalk/base.css";
import _Badge from "element-ui/lib/badge";
import _CollapseItem2 from "element-ui/lib/theme-chalk/collapse-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _CollapseItem from "element-ui/lib/collapse-item";
import _Collapse2 from "element-ui/lib/theme-chalk/collapse.css";
import "element-ui/lib/theme-chalk/base.css";
import _Collapse from "element-ui/lib/collapse";
import _Tag2 from "element-ui/lib/theme-chalk/tag.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tag from "element-ui/lib/tag";
import _Rate2 from "element-ui/lib/theme-chalk/rate.css";
import "element-ui/lib/theme-chalk/base.css";
import _Rate from "element-ui/lib/rate";
import _RadioGroup2 from "element-ui/lib/theme-chalk/radio-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _RadioGroup from "element-ui/lib/radio-group";
import _Radio2 from "element-ui/lib/theme-chalk/radio.css";
import "element-ui/lib/theme-chalk/base.css";
import _Radio from "element-ui/lib/radio";
import _Submenu2 from "element-ui/lib/theme-chalk/submenu.css";
import "element-ui/lib/theme-chalk/base.css";
import _Submenu from "element-ui/lib/submenu";
import _MenuItem2 from "element-ui/lib/theme-chalk/menu-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _MenuItem from "element-ui/lib/menu-item";
import _Menu2 from "element-ui/lib/theme-chalk/menu.css";
import "element-ui/lib/theme-chalk/base.css";
import _Menu from "element-ui/lib/menu";
import _DatePicker2 from "element-ui/lib/theme-chalk/date-picker.css";
import "element-ui/lib/theme-chalk/base.css";
import _DatePicker from "element-ui/lib/date-picker";
import _TableColumn2 from "element-ui/lib/theme-chalk/table-column.css";
import "element-ui/lib/theme-chalk/base.css";
import _TableColumn from "element-ui/lib/table-column";
import _Table2 from "element-ui/lib/theme-chalk/table.css";
import "element-ui/lib/theme-chalk/base.css";
import _Table from "element-ui/lib/table";
import _Upload2 from "element-ui/lib/theme-chalk/upload.css";
import "element-ui/lib/theme-chalk/base.css";
import _Upload from "element-ui/lib/upload";
import _Divider2 from "element-ui/lib/theme-chalk/divider.css";
import "element-ui/lib/theme-chalk/base.css";
import _Divider from "element-ui/lib/divider";
import _Aside2 from "element-ui/lib/theme-chalk/aside.css";
import "element-ui/lib/theme-chalk/base.css";
import _Aside from "element-ui/lib/aside";
import _Container2 from "element-ui/lib/theme-chalk/container.css";
import "element-ui/lib/theme-chalk/base.css";
import _Container from "element-ui/lib/container";
import _Loading2 from "element-ui/lib/theme-chalk/loading.css";
import "element-ui/lib/theme-chalk/base.css";
import _Loading from "element-ui/lib/loading";
import _CascaderPanel2 from "element-ui/lib/theme-chalk/cascader-panel.css";
import "element-ui/lib/theme-chalk/base.css";
import _CascaderPanel from "element-ui/lib/cascader-panel";
import _Backtop2 from "element-ui/lib/theme-chalk/backtop.css";
import "element-ui/lib/theme-chalk/base.css";
import _Backtop from "element-ui/lib/backtop";
import _Image2 from "element-ui/lib/theme-chalk/image.css";
import "element-ui/lib/theme-chalk/base.css";
import _Image from "element-ui/lib/image";
import _Footer2 from "element-ui/lib/theme-chalk/footer.css";
import "element-ui/lib/theme-chalk/base.css";
import _Footer from "element-ui/lib/footer";
import _Main2 from "element-ui/lib/theme-chalk/main.css";
import "element-ui/lib/theme-chalk/base.css";
import _Main from "element-ui/lib/main";
import _Header2 from "element-ui/lib/theme-chalk/header.css";
import "element-ui/lib/theme-chalk/base.css";
import _Header from "element-ui/lib/header";
import _Card2 from "element-ui/lib/theme-chalk/card.css";
import "element-ui/lib/theme-chalk/base.css";
import _Card from "element-ui/lib/card";
import _Spinner2 from "element-ui/lib/theme-chalk/spinner.css";
import "element-ui/lib/theme-chalk/base.css";
import _Spinner from "element-ui/lib/spinner";
import _Progress2 from "element-ui/lib/theme-chalk/progress.css";
import "element-ui/lib/theme-chalk/base.css";
import _Progress from "element-ui/lib/progress";
import _Col2 from "element-ui/lib/theme-chalk/col.css";
import "element-ui/lib/theme-chalk/base.css";
import _Col from "element-ui/lib/col";
import _Row2 from "element-ui/lib/theme-chalk/row.css";
import "element-ui/lib/theme-chalk/base.css";
import _Row from "element-ui/lib/row";
import _Switch2 from "element-ui/lib/theme-chalk/switch.css";
import "element-ui/lib/theme-chalk/base.css";
import _Switch from "element-ui/lib/switch";
import _Slider2 from "element-ui/lib/theme-chalk/slider.css";
import "element-ui/lib/theme-chalk/base.css";
import _Slider from "element-ui/lib/slider";
import _Alert2 from "element-ui/lib/theme-chalk/alert.css";
import "element-ui/lib/theme-chalk/base.css";
import _Alert from "element-ui/lib/alert";
import _TabPane2 from "element-ui/lib/theme-chalk/tab-pane.css";
import "element-ui/lib/theme-chalk/base.css";
import _TabPane from "element-ui/lib/tab-pane";
import _Tabs2 from "element-ui/lib/theme-chalk/tabs.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tabs from "element-ui/lib/tabs";
import _FormItem2 from "element-ui/lib/theme-chalk/form-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _FormItem from "element-ui/lib/form-item";
import _Form2 from "element-ui/lib/theme-chalk/form.css";
import "element-ui/lib/theme-chalk/base.css";
import _Form from "element-ui/lib/form";
import _Tooltip2 from "element-ui/lib/theme-chalk/tooltip.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tooltip from "element-ui/lib/tooltip";
import _Popover2 from "element-ui/lib/theme-chalk/popover.css";
import "element-ui/lib/theme-chalk/base.css";
import _Popover from "element-ui/lib/popover";
import _Button2 from "element-ui/lib/theme-chalk/button.css";
import "element-ui/lib/theme-chalk/base.css";
import _Button from "element-ui/lib/button";
import _Option2 from "element-ui/lib/theme-chalk/option.css";
import "element-ui/lib/theme-chalk/base.css";
import _Option from "element-ui/lib/option";
import _Select2 from "element-ui/lib/theme-chalk/select.css";
import "element-ui/lib/theme-chalk/base.css";
import _Select from "element-ui/lib/select";
import _CheckboxGroup2 from "element-ui/lib/theme-chalk/checkbox-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _CheckboxGroup from "element-ui/lib/checkbox-group";
import _Checkbox2 from "element-ui/lib/theme-chalk/checkbox.css";
import "element-ui/lib/theme-chalk/base.css";
import _Checkbox from "element-ui/lib/checkbox";
import _InputNumber2 from "element-ui/lib/theme-chalk/input-number.css";
import "element-ui/lib/theme-chalk/base.css";
import _InputNumber from "element-ui/lib/input-number";
import _Input2 from "element-ui/lib/theme-chalk/input.css";
import "element-ui/lib/theme-chalk/base.css";
import _Input from "element-ui/lib/input";
import _DropdownItem2 from "element-ui/lib/theme-chalk/dropdown-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _DropdownItem from "element-ui/lib/dropdown-item";
import _DropdownMenu2 from "element-ui/lib/theme-chalk/dropdown-menu.css";
import "element-ui/lib/theme-chalk/base.css";
import _DropdownMenu from "element-ui/lib/dropdown-menu";
import _Dropdown2 from "element-ui/lib/theme-chalk/dropdown.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dropdown from "element-ui/lib/dropdown";
import _Autocomplete2 from "element-ui/lib/theme-chalk/autocomplete.css";
import "element-ui/lib/theme-chalk/base.css";
import _Autocomplete from "element-ui/lib/autocomplete";
import _Dialog2 from "element-ui/lib/theme-chalk/dialog.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dialog from "element-ui/lib/dialog";
import _Pagination2 from "element-ui/lib/theme-chalk/pagination.css";
import "element-ui/lib/theme-chalk/base.css";
import _Pagination from "element-ui/lib/pagination";
import Vue from 'vue';
import App from './App.vue';
import 'normalize.css/normalize.css';
import router from './router/index';
import store from './store'; //引入store
import VueRouter from 'vue-router';
import '@/assets/icons'; // icon
import '@/permissions';
import '@/assets/styles/common.scss';
import { EventBus } from '@/utils/eventBus';
import i18n from './lang';
import ViewImagePopup from './utils/useViewImagePopup';
// 全局指令
import directives from '@/directive/index';
import FBSignInButton from 'vue-facebook-signin-button';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
//代码高亮
import 'highlight.js/styles/monokai-sublime.css';
import VueKonva from 'vue-konva';
import '@/assets/styles/custom.scss';
import '@/assets/fonts/iconfont.css';
Vue.use(VueKonva);
Vue.use(FBSignInButton);
// 全局的EventBus
Vue.prototype.$EventBus = EventBus;
Vue.prototype.$viewImage = ViewImagePopup.install;
var isDebug_mode = process.env.NODE_ENV === 'development';
Vue.config.devtools = isDebug_mode;
Vue.config.productionTip = isDebug_mode;
Vue.component('ElImageViewer', ElImageViewer);
Vue.use(VueRouter);
Vue.use(_Pagination);
Vue.use(_Dialog);
Vue.use(_Autocomplete);
Vue.use(_Dropdown);
Vue.use(_DropdownMenu);
Vue.use(_DropdownItem);
Vue.use(_Input);
Vue.use(_InputNumber);
Vue.use(_Checkbox);
Vue.use(_CheckboxGroup);
Vue.use(_Select);
Vue.use(_Option);
Vue.use(_Button);
Vue.use(_Popover);
Vue.use(_Tooltip);
Vue.use(_Form);
Vue.use(_FormItem);
Vue.use(_Tabs);
Vue.use(_TabPane);
Vue.use(_Alert);
Vue.use(_Slider);
Vue.use(_Switch);
Vue.use(_Row);
Vue.use(_Col);
Vue.use(_Progress);
Vue.use(_Spinner);
Vue.use(_Card);
Vue.use(_Header);
Vue.use(_Main);
Vue.use(_Footer);
Vue.use(_Image);
Vue.use(_Backtop);
Vue.use(_CascaderPanel);
Vue.use(_Loading.directive);
Vue.use(_Container);
Vue.use(_Aside);
Vue.use(_Divider);
Vue.use(_Upload);
// Vue.use(DateTimePicker);
Vue.use(_Table);
Vue.use(_TableColumn);
Vue.use(_DatePicker);
Vue.use(_Menu);
Vue.use(_MenuItem);
Vue.use(_Submenu);
Vue.use(_Radio);
Vue.use(_RadioGroup);
Vue.use(_Rate);
Vue.use(_Tag);
Vue.use(_Collapse);
Vue.use(_CollapseItem);
Vue.use(_Badge);
Vue.use(_InfiniteScroll);
Vue.use(_Drawer);
Vue.use(_Carousel);
Vue.use(_CarouselItem);
Vue.use(_Empty);
Vue.use(_Steps);
Vue.use(_Step);
Vue.prototype.$loading = _Loading.service;
Vue.prototype.$alert = _MessageBox.alert;
Vue.prototype.$confirm = _MessageBox.confirm;
Vue.prototype.$prompt = _MessageBox.prompt;
Vue.prototype.$notify = _Notification;
Vue.prototype.$message = _Message;
Vue.prototype.$CHINA = location.host !== 'e.seaart.ai';
Vue.prototype.$PAGE_ENV = location.host === 'e.seaart.ai';
Vue.use(directives); // 全局指令

new Vue({
  el: '#app',
  store: store,
  router: router,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  }
});