function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import Vue from 'vue';
import ViewImagePopup from '@/components/ViewImagePopup';
var ViewPopup = Vue.extend(ViewImagePopup); // 创建构造器

ViewImagePopup.install = function () {
  var imageList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var showIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (!imageList.length) {
    return;
  }
  var data = {
    imageList: imageList.map(function (img) {
      var isObj = _typeof(img) === 'object';
      return isObj ? img.url || img.uri || img.tempURL : img;
    }),
    showIndex: showIndex
  };
  // 创建组件对象，并挂载
  var instance = new ViewPopup({
    data: data
  }).$mount();
  // 将组件添加到dom中
  document.body.appendChild(instance.$el);
  // 调用方法或操作属性
  Vue.nextTick(function () {
    instance.show();
  });
};
export default ViewImagePopup;