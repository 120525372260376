// 文件上传
import { confirmPreSignFile, getPreSignInfo } from '@/api/public';
function UploadFile(_ref) {
  var type = _ref.type;
  this.type = type;
  this.fileId = '';
  this.fileName = '';
  this.uploadPath = '';
  this.file = null;
  this.init();
}
UploadFile.prototype = {
  init: function init() {
    this.fileId = '';
    this.uploadPath = '';
    this.file = null;
  },
  startUpload: function startUpload(file) {
    var _this = this;
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var type = params.type,
      name = params.name,
      size = params.size,
      category = params.category,
      needFileId = params.needFileId;
    var _this$getFileInfo = this.getFileInfo(file),
      fileName = _this$getFileInfo.fileName,
      fileType = _this$getFileInfo.fileType,
      fileSize = _this$getFileInfo.fileSize;
    this.file = file;
    this.type = category || this.type;
    this.fileName = name || fileName;
    this.fileSize = size || fileSize;
    this.fileType = type || fileType;
    return new Promise(function (resolve, reject) {
      _this.getSignParam(_this.file).then(function (res) {
        var _res$data = res.data,
          file_id = _res$data.file_id,
          pre_sign = _res$data.pre_sign;
        _this.fileId = file_id;
        _this.uploadPath = pre_sign;
      }).then(function (res) {
        return _this.upload(_this.uploadPath);
      }).then(function (res) {
        return _this.uploadConfirm();
      }).then(function (res) {
        var _res$data2 = res.data,
          url = _res$data2.url,
          file_id = _res$data2.file_id;
        if (needFileId) {
          resolve({
            url: url,
            file_id: file_id
          });
        } else {
          resolve(url);
        }
      }).catch(function (err) {
        reject(err);
      });
    });
  },
  getFileInfo: function getFileInfo(file) {
    var _ref2 = file || {},
      fileSize = _ref2.size,
      fileName = _ref2.name;
    var fileType = fileName && fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    return {
      fileName: fileName,
      fileType: fileType,
      fileSize: fileSize
    };
  },
  getSignParam: function getSignParam() {
    return getPreSignInfo({
      content_type: "image/".concat(this.fileType),
      file_name: this.fileName,
      file_size: this.fileSize,
      category: this.type
    });
  },
  /**
   * 如果用户访问的是haiyiai.com 或者 seaart.ai,但是用户语言选择中文时
   * 预签名上传在调用storage.googleapis.com这个域名失败时
   * 改为upload.api.haiyiai.com这个域名重新预签名上传
   * 如果再次失败 则返回错误信息
   */
  upload: function upload(uploadPath) {
    var _this2 = this;
    return new Promise(function (resolve, reject) {
      var isHaiyi = location.href.includes('haiyiai.com');
      var isSeaart = location.href.includes('seaart.ai');
      var needResend = isHaiyi || isSeaart && localStorage.getItem('language') === 'zh-CN';
      var isGoogleUpload = uploadPath.includes('storage.googleapis.com');
      var file = _this2.file;
      var formData = new FormData();
      formData.append('file', file, _this2.fileName);
      // 1. 创建 xhr 对象
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          _this2.step = 2;
          resolve(xhr.responseText ? JSON.parse(xhr.responseText) : null);
        } else {
          var standby_xhr = new XMLHttpRequest();
          if (needResend && isGoogleUpload) {
            uploadPath = uploadPath.replace('storage.googleapis.com', 'upload.api.haiyiai.com');
            standby_xhr.onload = function () {
              if (standby_xhr.status >= 200 && standby_xhr.status < 300) {
                resolve(standby_xhr.responseText ? JSON.parse(standby_xhr.responseText) : null);
              } else {
                reject(new Error(standby_xhr.statusText));
              }
            };
            standby_xhr.onerror = function (e) {
              reject(e);
            };
            standby_xhr.open('PUT', uploadPath);
            standby_xhr.setRequestHeader('Content-Type', 'image/' + _this2.fileType);
            standby_xhr.send(file);
          } else {
            reject(new Error(xhr.statusText));
          }
        }
      };
      xhr.onerror = function (e) {
        var onerror_xhr = new XMLHttpRequest();
        if (needResend && isGoogleUpload) {
          uploadPath = uploadPath.replace('storage.googleapis.com', 'upload.api.haiyiai.com');
          onerror_xhr.onload = function () {
            if (onerror_xhr.status >= 200 && onerror_xhr.status < 300) {
              resolve(onerror_xhr.responseText ? JSON.parse(onerror_xhr.responseText) : null);
            } else {
              reject(new Error(onerror_xhr.statusText));
            }
          };
          onerror_xhr.onerror = function (e) {
            reject(e);
          };
          onerror_xhr.open('PUT', uploadPath);
          onerror_xhr.setRequestHeader('Content-Type', 'image/' + _this2.fileType);
          onerror_xhr.send(file);
        } else {
          reject(e);
        }
      };
      // 2. 调用 open 函数，指定请求类型与URL地址。其中，请求类型必须为 post
      xhr.open('PUT', uploadPath);
      xhr.setRequestHeader('Content-Type', 'image/' + _this2.fileType);
      // 3. 发起请求
      xhr.send(file);
    });
  },
  uploadConfirm: function uploadConfirm() {
    return confirmPreSignFile({
      category: this.type,
      file_id: this.fileId
    });
  }
};
export default UploadFile;