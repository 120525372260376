import Vue from 'vue';
import Router from 'vue-router';
import routes from './router';
Vue.use(Router);
var router = new Router({
  routes: routes,
  mode: 'history',
  scrollBehavior: function scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  }
});

// 处理跳转相同路由报错
var routerPush = Router.prototype.push;
Router.prototype.push = function (location) {
  return routerPush.call(this, location).catch(function (error) {
    return error;
  });
};
export default router;