var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "api-menu"
  }, [_c("div", {
    staticClass: "menu-item"
  }, [_c("div", {
    staticClass: "menu-title"
  }, [_vm._v("\n      API文档\n      "), _c("i", {
    class: _vm.apiZoom ? "el-icon-arrow-down" : "el-icon-arrow-up",
    on: {
      click: function click($event) {
        _vm.apiZoom = !_vm.apiZoom;
      }
    }
  })]), _vm._v(" "), _c("ul", {
    class: {
      hide: _vm.apiZoom
    }
  }, _vm._l(_vm.apiMenu, function (menu, i) {
    return _c("li", {
      key: "menu_" + i,
      class: {
        active: _vm.activeIndex === i
      },
      attrs: {
        text: menu
      },
      on: {
        click: function click($event) {
          _vm.activeIndex = i;
        }
      }
    });
  }), 0)]), _vm._v(" "), _c("div", {
    staticClass: "menu-item"
  }, [_vm._v("\n    常见问题\n  ")]), _vm._v(" "), _c("div", {
    staticClass: "menu-item"
  }, [_vm._v("\n    联系我们\n  ")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };