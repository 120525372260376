var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "layout-left",
    class: {
      zoom: _vm.isCollapse
    }
  }, [_c("a", {
    staticClass: "logos",
    attrs: {
      href: "/",
      text: "| " + _vm.$t("EnterpriseEdition")
    }
  }), _vm._v(" "), _c("el-menu", {
    staticClass: "left-menu",
    attrs: {
      router: "",
      "default-active": _vm._f("pathIndex")(_vm.$route.path),
      "background-color": "#1A1D1F",
      "text-color": "#cfdedc",
      "active-text-color": "#409EFF",
      collapse: _vm.isCollapse
    },
    on: {
      select: _vm.goToPage
    }
  }, [_vm._l(_vm.Menu, function (menu) {
    return [_c("el-menu-item", {
      key: menu.path,
      attrs: {
        index: menu.path
      }
    }, [_c("i", {
      class: menu.meta.icon
    }), _vm._v(" "), _c("span", {
      attrs: {
        slot: "title"
      },
      slot: "title"
    }, [_vm._v(_vm._s(_vm.$t(menu.name)))])])];
  })], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };