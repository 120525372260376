import { isSupportWebp } from '@/utils/tool';
var state = {
  isFixed: true,
  isSupportWebp: isSupportWebp(),
  tagHot: [],
  showMask: false,
  homeType: 'work',
  // Checkpoint LORA work
  imagePageFromPath: '',
  clientComputing: 1,
  // 1=>local 2=>platform 
  lang: 'zhCn'
};
var mutations = {
  changeIsfixed: function changeIsfixed(state, data) {
    state.isFixed = data;
  },
  setTagHot: function setTagHot(state, data) {
    data.forEach(function (element) {
      element.style = JSON.parse(element.style);
    });
    state.tagHot = data;
  },
  setMask: function setMask(state, data) {
    state.showMask = !!data;
  },
  setHomeType: function setHomeType(state, data) {
    state.homeType = data;
  },
  setImagePageFromPath: function setImagePageFromPath(state, data) {
    state.imagePageFromPath = data;
  },
  setClientComputing: function setClientComputing(state, data) {
    state.clientComputing = data;
  },
  SET_LANG: function SET_LANG(state, lang) {
    state.lang = lang;
  }
};
var actions = {
  setLang: function setLang(_ref, lang) {
    var commit = _ref.commit;
    commit('SET_LANG', lang);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};