var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "renewal-page",
    style: {
      fontSize: _vm.fontSize + "px"
    }
  }, [_c("div", {
    staticClass: "renewal-panel"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t("Renewal1")))]), _vm._v(" "), _c("div", {
    staticClass: "renewal-body"
  }, [_c("div", {
    staticClass: "combo-info"
  }, [_c("p", {
    staticClass: "combo-title"
  }, [_vm._v(_vm._s(_vm.$t("Renewal2")))]), _vm._v(" "), _c("div", {
    staticClass: "combo-name"
  }, [_vm._v(_vm._s(_vm.$t(_vm.COMBO_TYPE[_vm.payInfo.level - 1])))])]), _vm._v(" "), _c("div", {
    staticClass: "renewal-info"
  }, [_c("div", {
    staticClass: "info-title"
  }, [_c("i", {
    staticClass: "el-icon-success"
  }), _vm._v("\n          " + _vm._s(_vm.$t("Renewal3")) + "\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "info-content"
  }, [_vm._v("\n          " + _vm._s(_vm.payInfo.gift_account_amount) + "个\n        ")])]), _vm._v(" "), _c("div", {
    staticClass: "renewal-info"
  }, [_c("div", {
    staticClass: "info-title"
  }, [_c("i", {
    staticClass: "el-icon-success"
  }), _vm._v("\n          " + _vm._s(_vm.$t("Renewal4")) + "\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "info-content"
  }, [_vm._v("\n          " + _vm._s(_vm.payInfo.gift_store)), _c("span", {
    staticClass: "tips"
  }, [_vm._v("(" + _vm._s(_vm.$t("Renewal5")) + ")")])])]), _vm._v(" "), _c("div", {
    staticClass: "renewal-info"
  }, [_c("div", {
    staticClass: "info-title"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "Charges"
    }
  }), _vm._v("\n          " + _vm._s(_vm.$t("Renewal6")) + "\n        ")], 1), _vm._v(" "), _c("div", {
    staticClass: "info-content"
  }, [_vm._v("\n          " + _vm._s(_vm.payInfo.charge_unit) + " " + _vm._s(_vm.$t(_vm.$CHINA ? "Renewal7" : "Renewal77")) + "\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "renewal-info"
  }, [_c("div", {
    staticClass: "info-title"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "GPU"
    }
  }), _vm._v("\n          " + _vm._s(_vm.$t("Renewal8")) + "\n        ")], 1), _vm._v(" "), _c("div", {
    staticClass: "info-content num-content"
  }, [_c("div", {
    staticClass: "number-input"
  }, [_c("span", {
    class: {
      disabled: _vm.hours <= 1
    },
    on: {
      click: function click($event) {
        return _vm.changeNum(-1);
      }
    }
  }, [_vm._v("-")]), _vm._v(" "), _c("el-input", {
    on: {
      input: _vm.changeHours
    },
    model: {
      value: _vm.hours,
      callback: function callback($$v) {
        _vm.hours = $$v;
      },
      expression: "hours"
    }
  }), _vm._v(" "), _c("span", {
    on: {
      click: function click($event) {
        return _vm.changeNum(1);
      }
    }
  }, [_vm._v("+")])], 1), _vm._v("\n          ≈ " + _vm._s(_vm._f("formatNumber")(_vm.imgNum)) + _vm._s(_vm.$t("Renewal9")) + "\n        ")])]), _vm._v(" "), _c("div", {
    staticClass: "renewal-info",
    attrs: {
      tips: _vm.$t("Renewal44")
    }
  }, [_c("div", {
    staticClass: "info-title"
  }, [_c("i", {
    staticClass: "el-icon-success"
  }), _vm._v("\n          " + _vm._s(_vm.$t("Renewal10")) + "\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "info-content"
  }, [_vm._v("\n          " + _vm._s(_vm.payInfo.gift_gpu) + " " + _vm._s(_vm.$t("Hours")) + "\n        ")])]), _vm._v(" "), _c("div", {
    staticClass: "renewal-tips",
    on: {
      click: _vm.viewFAQ
    }
  }, [_c("i", {
    staticClass: "el-icon-info"
  }), _vm._v(_vm._s(_vm.$t("Renewal12")) + "\n      ")])]), _vm._v(" "), _c("div", {
    staticClass: "renewal-price"
  }, [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_vm._v(_vm._s(_vm.$t("Renewal13")) + "...")]), _vm._v("\n      " + _vm._s(_vm.loading ? "" : (_vm.$CHINA ? "￥" : "$") + _vm.payInfo.act_price) + "\n    ")]), _vm._v(" "), _c("div", {
    staticClass: "renewal-btn"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading,
      disabled: _vm.hours < 1
    },
    on: {
      click: _vm.payment
    }
  }, [_vm._v(_vm._s(_vm.$t("Renewal15")))])], 1)]), _vm._v(" "), _c("f-a-q", {
    ref: "FAQ"
  }), _vm._v(" "), _c("payment-dialog", {
    ref: "paymentDialog",
    on: {
      showSueecssDialog: _vm.showSueecssDialog
    }
  }), _vm._v(" "), _c("payment-status-dialog", {
    ref: "payStatusDialog"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };