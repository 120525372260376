var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "account-manage"
  }, [_c("table-filter", {
    attrs: {
      config: _vm.filterConfig
    },
    on: {
      search: _vm.search
    }
  }, [_c("el-button", {
    staticClass: "custom-btn",
    attrs: {
      slot: "btn",
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.openSetLimit
    },
    slot: "btn"
  }, [_vm._v("\n      " + _vm._s(_vm.$t("Account37")) + "\n    ")])], 1), _vm._v(" "), _c("c-table", {
    ref: "customTable",
    attrs: {
      config: _vm.config
    },
    scopedSlots: _vm._u([{
      key: "leftBtn",
      fn: function fn(_ref) {
        var selectData = _ref.selectData;
        return [_c("el-button", {
          staticClass: "custom-btn",
          attrs: {
            type: "primary",
            plain: "",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.handleEvent(selectData, "ADD_USER");
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("Account8")))]), _vm._v(" "), _c("el-button", {
          staticClass: "custom-btn",
          attrs: {
            type: "primary",
            size: "mini",
            plain: "",
            disabled: !selectData.length
          },
          on: {
            click: function click($event) {
              return _vm.handleEvent(selectData, "EXPORT");
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("Account9")))])];
      }
    }, {
      key: "rightBtn",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  }), _vm._v(" "), _c("SetLimit", {
    ref: "setLimit"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };