import router from '@/router/router';
import { API_URL } from '@/utils/common';
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var list = router.filter(function (item) {
      var _item$meta;
      return !((_item$meta = item.meta) !== null && _item$meta !== void 0 && _item$meta.full);
    });
    return {
      Menu: list,
      API_URL: API_URL
    };
  },
  filters: {
    pathIndex: function pathIndex(val) {
      var path = val.split('/')[1];
      return '/' + path;
    }
  },
  methods: {
    goToPage: function goToPage(index, indexPath) {
      if (index === '/API') {
        window.open(this.API_URL[this.$i18n.locale]);
      }
    }
  }
};