var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "api-page"
  }, [_c("p", [_vm._v(_vm._s(_vm.$t("API1")) + " "), _c("a", {
    attrs: {
      href: _vm.API_URL[_vm.$i18n.locale],
      target: "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("API2")))])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };