var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "fap-dialog big-head-dialog custom-dialog",
    attrs: {
      visible: _vm.isShow,
      width: "50vw"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isShow = $event;
      }
    }
  }, [_c("div", {
    staticClass: "faq-title"
  }, [_vm._v(_vm._s(_vm.$t("Renewal18")))]), _vm._v(" "), _c("div", {
    staticClass: "dialog-body"
  }, [_vm._l(_vm.faqList, function (faq, i) {
    return _c("div", {
      key: "faq_" + i,
      staticClass: "faq-item"
    }, [_c("div", {
      staticClass: "faq-question"
    }, [_vm._v(_vm._s(faq.q))]), _vm._v(" "), _c("div", {
      staticClass: "faq-answer",
      style: {
        height: _vm.zoomIndex.includes(i) ? "0px" : "auto"
      },
      domProps: {
        innerHTML: _vm._s(faq.a)
      }
    }), _vm._v(" "), _c("span", {
      staticClass: "zoom-icon",
      on: {
        click: function click($event) {
          return _vm.zoom(i);
        }
      }
    }, [_vm._v(_vm._s(_vm.zoomIndex.includes(i) ? "+" : "-"))])]);
  }), _vm._v(" "), _c("i", {
    staticClass: "el-icon-close close-icon",
    on: {
      click: _vm.close
    }
  })], 2)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };