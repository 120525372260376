import LineChart from './components/LineChart.vue';
import { getCompanyUsageData as _getCompanyUsageData } from '@/api/analysis';
import { remainingTimeFormat } from '@/utils/diffDate';
export default {
  components: {
    LineChart: LineChart
  },
  data: function data() {
    return {
      usageInfo: {},
      remainingTimeFormat: remainingTimeFormat
    };
  },
  filters: {
    BToG: function BToG(val) {
      var num = val / 1024 / 1024; // MB
      if (num / 1024 < 1) {
        return num.toFixed(2) + 'MB';
      }
      if (num / 1024 / 1024 < 1) {
        return (num / 1024).toFixed(2) + 'G';
      }
      return (num / 1024 / 1024).toFixed(2) + 'T';
    }
  },
  created: function created() {
    this.getCompanyUsageData();
  },
  methods: {
    getCompanyUsageData: function getCompanyUsageData() {
      var _this = this;
      _getCompanyUsageData().then(function (res) {
        _this.usageInfo = res.data;
      });
    }
  }
};