var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "purchase-page"
  }, [_c("sub-menu"), _vm._v(" "), _c("div", {
    staticClass: "sub-body"
  }, [_c("router-view")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };