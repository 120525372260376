var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sub-menu"
  }, [_c("el-menu", {
    staticClass: "custom-menu",
    attrs: {
      "default-active": _vm.$route.path,
      mode: "horizontal",
      router: "",
      "background-color": "#1A1D1F",
      "text-color": "#E5EAF3",
      "active-text-color": "#409EFF"
    }
  }, _vm._l(_vm.Menu, function (menu, i) {
    var _menu$meta;
    return _c("el-menu-item", {
      key: "menu_" + i,
      attrs: {
        index: menu.path
      }
    }, [_vm._v("\n      " + _vm._s(_vm.$t(menu.name)) + "\n      "), menu !== null && menu !== void 0 && (_menu$meta = menu.meta) !== null && _menu$meta !== void 0 && _menu$meta.tips ? _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: menu.meta.tips,
        placement: "top"
      }
    }, [_c("i", {
      staticClass: "el-icon-info"
    })]) : _vm._e()], 1);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };