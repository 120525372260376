import { getConfigInfo, saveConfig } from '@/api/devConfig';
export default {
  data: function data() {
    return {
      isShow: false,
      loading: false,
      clientId: '',
      secret: '',
      formData: {
        endpoint: '',
        event_subscription: {
          'task.finish': 0,
          'task.sys_cancel': 0
        },
        secret: ''
      },
      formRules: {
        endpoint: [{
          required: true,
          message: this.$t('DevConfig8')
        }],
        secret: [{
          required: true,
          message: this.$t('DevConfig9')
        }]
      }
    };
  },
  created: function created() {
    this.getInfo();
  },
  methods: {
    getInfo: function getInfo() {
      var _this = this;
      this.loading = true;
      getConfigInfo().then(function (res) {
        var _res$data = res.data,
          client_id = _res$data.client_id,
          secret = _res$data.secret,
          webhook_config = _res$data.webhook_config;
        _this.clientId = client_id;
        _this.secret = secret;
        webhook_config.event_subscription['task.finish'] = Boolean(webhook_config.event_subscription['task.finish']);
        webhook_config.event_subscription['task.sys_cancel'] = Boolean(webhook_config.event_subscription['task.sys_cancel']);
        _this.formData = webhook_config;
      }).finally(function () {
        _this.loading = false;
      });
    },
    saveConfig: function saveConfig() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this2.saveData();
        }
      });
    },
    saveData: function saveData() {
      var _this3 = this;
      this.loading = true;
      var params = JSON.parse(JSON.stringify(this.formData));
      params.event_subscription['task.finish'] = params.event_subscription['task.finish'] ? 1 : 0;
      params.event_subscription['task.sys_cancel'] = params.event_subscription['task.sys_cancel'] ? 1 : 0;
      saveConfig(params).then(function (res) {
        _this3.$message.success(_this3.$t('DevConfig10'));
      }).catch(function (err) {
        _this3.$message.success(_this3.$t('DevConfig11'));
      }).finally(function () {
        _this3.loading = false;
      });
    },
    resetForm: function resetForm() {
      this.formData = {
        endpoint: '',
        event_subscription: {
          'task.finish': false,
          'task.sys_cancel': false
        },
        secret: ''
      };
    }
  }
};