import fetch from '@/utils/fetch';
var publicPath = '/api/v1/manager/';
export function getConfigInfo(data) {
  return fetch({
    url: publicPath + 'developer/config',
    method: 'post',
    data: data
  });
}
export function saveConfig(data) {
  return fetch({
    url: publicPath + 'developer/config/webhook-edit',
    method: 'post',
    data: data
  });
}