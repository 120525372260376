function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
// 进行多语言支持配置
import Vue from 'vue'; // 引入Vue
import VueI18n from 'vue-i18n'; // 引入国际化的插件包
import store from '@/store';
import locale from 'element-ui/lib/locale';
import elementEN from 'element-ui/lib/locale/lang/en'; // 引入饿了么的英文包
import elementZH from 'element-ui/lib/locale/lang/zh-CN'; // 引入饿了么的中文包
// import elementJA from 'element-ui/lib/locale/lang/ja'; // 引入饿了么的日文包
// import elementAR from 'element-ui/lib/locale/lang/ar'; // 引入饿了么的阿拉伯语包
// import elementKO from 'element-ui/lib/locale/lang/ko'; // 引入饿了么的韩语包
// import elementDE from 'element-ui/lib/locale/lang/de'; // 引入饿了么的德语包
// import elementFR from 'element-ui/lib/locale/lang/fr'; // 引入饿了么的法语包
// import elementRU from 'element-ui/lib/locale/lang/ru-RU'; // 引入饿了么的俄语包
// import elementES from 'element-ui/lib/locale/lang/es'; // 引入饿了么的西班牙语包
// import elementPT from 'element-ui/lib/locale/lang/pt'; // 引入饿了么的葡萄牙语包
// import elementTH from 'element-ui/lib/locale/lang/th'; // 引入饿了么的泰语包
import elementZHTW from 'element-ui/lib/locale/lang/zh-TW'; // 引入饿了么的繁体包
import zhCn from './zhCn'; // 引入中文
import zhTw from './zhTw'; // 引入繁体
import en from './en'; // 引入英文
// import ja from './ja'; // 引入日文
// import ar from './ar'; // 引入阿拉伯语
// import ko from './ko'; // 引入韩语
// import de from './de'; // 引入德语
// import fr from './fr'; // 引入法语
// import ru from './ru'; // 引入俄语
// import es from './es'; // 引入西班牙语
// import pt from './pt'; // 引入葡萄牙语
// import th from './th'; // 引入泰语

Vue.use(VueI18n); // 全局注册国际化包
console.log('store.state.app.lang: ' + store.state.app.lang);
//获取本地语种
var lang = store.state.app.lang || navigator.language || navigator.browserLanguage;
// if (lang == 'zh') {
// 	lang = 'zh-CN';
// }
// 创建国际化插件的实例
var i18n = new VueI18n({
  // 指定语言类型 zh表示中文  en表示英文
  locale: lang,
  // 将elementUI语言包加入到插件语言数据里
  messages: {
    // 英文环境下的语言数据
    en: _objectSpread(_objectSpread({}, elementEN), en),
    // 中文环境下的语言数据
    zhCn: _objectSpread(_objectSpread({}, elementZH), zhCn),
    zhTw: _objectSpread(_objectSpread({}, elementZHTW), zhTw)
    // // 日文环境下的语言数据
    // ja: {
    // 	...elementJA,
    // 	...ja
    // },
    // // 阿拉伯语环境下的语言数据
    // ar: {
    // 	...elementAR,
    // 	...ar
    // },
    // // 韩语境下的语言数据
    // ko: {
    // 	...elementKO,
    // 	...ko
    // },
    // // 德语境下的语言数据
    // de: {
    // 	...elementDE,
    // 	...de
    // },
    // // 法语境下的语言数据
    // fr: {
    // 	...elementFR,
    // 	...fr
    // },
    // // 俄语境下的语言数据
    // ru: {
    // 	...elementRU,
    // 	...ru
    // },
    // // 西班牙语境下的语言数据
    // es: {
    // 	...elementES,
    // 	...es
    // },
    // // 葡萄牙语境下的语言数据
    // pt: {
    // 	...elementPT,
    // 	...pt
    // },
    // // 泰语境下的语言数据
    // th: {
    // 	...elementTH,
    // 	...th
    // }
  },

  // 不显示翻译错误的warning信息
  silentTranslationWarn: true
});
// 配置elementUI 语言转换关系
locale.i18n(function (key, value) {
  return i18n.t(key, value);
});
export default i18n;

// 重新封装方法
export function $t(args) {
  return i18n.tc.call(i18n, args);
}