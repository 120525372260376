var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "purchase-history"
  }, [_c("c-table", {
    ref: "customTable",
    attrs: {
      config: _vm.config
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };