import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
import { TitleComponent, TooltipComponent, GridComponent, DatasetComponent, TransformComponent } from "echarts/components";
import { getAnalysisData as _getAnalysisData } from "@/api/analysis";

// 注册必须的组件
echarts.use([TitleComponent, TooltipComponent, GridComponent, DatasetComponent, TransformComponent, LineChart, LabelLayout, UniversalTransition, CanvasRenderer]);
export default {
  props: ["type"],
  data: function data() {
    return {
      datePicker: new Date()
    };
  },
  computed: {
    title: function title() {
      return this.type === "TIME" ? this.$t("Analysis12") : this.$t("Analysis13");
    }
  },
  created: function created() {
    this.getAnalysisData();
  },
  methods: {
    getAnalysisData: function getAnalysisData() {
      var _this = this;
      var date = new Date(this.datePicker);
      date.setDate(1);
      date.setHours(0);
      date.setSeconds(0);
      date.setMinutes(0);
      var startTime = this.getUTC(date);
      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
      date.setHours(0);
      date.setSeconds(0);
      var endTime = this.getUTC(date) - 1000;
      var params = {
        time_ms_range: {
          from: startTime,
          to: endTime
        },
        type: this.type
      };
      _getAnalysisData(params).then(function (res) {
        _this.initEcharts(res.data);
      });
    },
    getUTC: function getUTC(date) {
      var timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
      var localDate = new Date(date.getTime() - timezoneOffset);
      return localDate.getTime();
    },
    changeDataFormat: function changeDataFormat(list) {
      var _this2 = this;
      return list.map(function (item) {
        var value = _this2.type === 'TIME' ? (item.gpu_usage / 3600000).toFixed(3) : (item.space_usage / 1024 / 1024 / 1024).toFixed(3);
        return parseFloat(value);
      });
    },
    initEcharts: function initEcharts(list) {
      var data = this.changeDataFormat(list);
      var dom = this.$refs.lineChart;
      var myChart = echarts.init(dom);
      var days = list.map(function (item, index) {
        return index + 1;
      });
      myChart.setOption({
        tooltip: {
          trigger: "axis"
        },
        grid: {
          left: "10%",
          right: "10%"
        },
        xAxis: {
          type: "category",
          data: days,
          name: this.$t("Analysis18"),
          nameGap: 10,
          boundaryGap: false
        },
        yAxis: {
          type: "value",
          name: this.$t("Analysis14") + (this.type === "TIME" ? this.$t("Analysis16") + "(h)" : this.$t("Analysis17") + "(G)")
        },
        series: [{
          data: data,
          type: "line",
          smooth: true,
          name: this.type === "TIME" ? this.$t("Analysis16") : this.$t("Analysis17")
        }]
      });
    }
  }
};