var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loginLoading,
      expression: "loginLoading"
    }],
    staticClass: "login-page"
  }, [_c("div", {
    staticClass: "login-panel"
  }, [_c("div", {
    staticClass: "login-title"
  }, [_vm._v("海艺企业后台")]), _vm._v(" "), _c("el-form", {
    ref: "loginForm",
    staticClass: "custom-form",
    attrs: {
      model: _vm.formData,
      "label-position": "top",
      rules: _vm.rule
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "账号",
      prop: "user_id",
      required: ""
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入账号",
      clearable: ""
    },
    model: {
      value: _vm.formData.user_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "user_id", $$v);
      },
      expression: "formData.user_id"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "密码",
      prop: "password",
      required: ""
    }
  }, [_c("el-input", {
    attrs: {
      type: "password",
      placeholder: "请输入密码",
      "show-password": ""
    },
    model: {
      value: _vm.formData.password,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "password", $$v);
      },
      expression: "formData.password"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "submit"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.loginLoading
    },
    on: {
      click: _vm.login
    }
  }, [_vm._v("登录")])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };