var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "font-size": "50px",
      color: "#1e80ff",
      height: "100vh"
    }
  }, [_vm._v("404")]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };