var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.uploading,
      expression: "uploading"
    }],
    staticClass: "custom-dialog",
    attrs: {
      visible: _vm.isShowDialog,
      "close-on-click-modal": false,
      width: "456px",
      "custom-class": "edit-avatar-dialog",
      "before-close": _vm.close
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isShowDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "tit"
  }, [_vm._v("更换头像和昵称")]), _vm._v(" "), _c("div", {
    staticClass: "tips"
  }, [_c("div", [_vm._v("\n        每月个人信息只能修改三次\n      ")])]), _vm._v(" "), _c("div", {
    staticClass: "upload-avatar"
  }, [_c("div", {
    staticClass: "avatar-uploader"
  }, [_c("div", {
    staticClass: "upload"
  }, [_c("label", {
    staticClass: "upload-demo",
    attrs: {
      for: "avatar"
    }
  }, [_vm.imageUrl ? _c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.imageUrl
    }
  }) : _c("i", {
    staticClass: "el-icon-plus avatar-uploader-icon"
  })]), _vm._v(" "), _c("input", {
    ref: "input",
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: "avatar",
      type: "file",
      accept: ".jpg,.jpeg,.png,"
    },
    on: {
      change: _vm.handleFileChange
    }
  })])]), _vm._v(" "), _c("div", {
    staticClass: "img-text"
  }, [_vm._v("上传")])]), _vm._v(" "), _c("el-form", {
    ref: "form",
    staticClass: "name",
    attrs: {
      model: _vm.formData,
      rules: _vm.formRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "昵称",
      prop: "nickname",
      required: ""
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入昵称",
      minlength: "2",
      maxlength: "20",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.nickname,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "nickname", $$v);
      },
      expression: "formData.nickname"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-btn",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "cancel-btn",
    attrs: {
      type: "default"
    },
    on: {
      click: _vm.close
    }
  }, [_vm._v("取消")]), _vm._v(" "), _c("el-button", {
    staticClass: "save-btn",
    attrs: {
      type: "primary",
      loading: _vm.uploading
    },
    on: {
      click: _vm.saveAvatarandName
    }
  }, [_vm._v("保存")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };