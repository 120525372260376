var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header custom-form"
  }, [_c("i", {
    class: _vm.isCollapse ? "el-icon-s-unfold" : "el-icon-s-fold",
    on: {
      click: _vm.zoom
    }
  }), _vm._v(" "), _c("i", {
    staticClass: "el-icon-refresh-right",
    on: {
      click: _vm.refreshPage
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "local"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t(_vm.localName)))])]), _vm._v(" "), _vm.isLoggedIn ? _c("div", {
    staticClass: "user-panel"
  }, [_c("el-dropdown", {
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.changeLang
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link"
  }, [_vm._v("\n        " + _vm._s(_vm.langList[_vm.lang])), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    staticClass: "custom-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.langList, function (item, key) {
    return _c("el-dropdown-item", {
      key: key,
      attrs: {
        command: key
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1)], 1), _vm._v(" "), _c("el-dropdown", {
    on: {
      command: _vm.handleEvent
    }
  }, [_c("user-avatar", {
    attrs: {
      user: _vm.userInfo,
      size: 36
    }
  }), _vm._v(" "), _c("el-dropdown-menu", {
    staticClass: "custom-dropdown header-menu",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "FULL"
    }
  }, [_c("i", {
    class: _vm.fullIcon
  }), _vm._v(_vm._s(_vm.$t(_vm.isFull ? "ExitFullscreen" : "FullScreen")) + "\n        ")]), _vm._v(" "), _c("el-dropdown-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      command: "PAS"
    }
  }, [_c("i", {
    staticClass: "el-icon-edit-outline"
  }), _vm._v(_vm._s(_vm.$t("ChangePassword")) + "\n        ")]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "OUT"
    }
  }, [_c("i", {
    staticClass: "el-icon-switch-button"
  }), _vm._v(_vm._s(_vm.$t("SignOut")) + "\n        ")])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c("set-user-popup", {
    ref: "setUserPopu"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };