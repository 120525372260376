// import { login } from '@
import { getMyAccount, logout } from '@/api/user';
var state = {
  token: '',
  used: '',
  // 用于判断是否已经登录过
  tempToken: '',
  userInfo: '',
  userID: '',
  name: '',
  real_name: '',
  avatar: '',
  // 账户信息
  accountData: '',
  isAdmin: false,
  // 是否有管理员权限
  status: 1,
  // 是否正常
  companyInfo: null // 企业信息
};

var getters = {
  isLoggedIn: function isLoggedIn(state) {
    return !!state.token;
  },
  // 判断用户是否已登录
  userInfo: function userInfo(state) {
    return state.userInfo;
  },
  // 获取用户信息
  userID: function userID(state) {
    return state.userID;
  },
  // 获取用户ID
  email: function email(state) {
    return state.email;
  },
  // 注册邮箱
  guideStepIndex: function guideStepIndex(state) {
    return state.guideStepIndex;
  },
  // 新手引导步骤
  nowServerTime: function nowServerTime(state) {
    return state.nowServerTime;
  },
  // 获取当前服务器时间
  name: function name(state) {
    return state.name;
  },
  // 获取用户名
  realName: function realName(state) {
    return state.real_name;
  },
  // 获取用户真实姓名
  avatar: function avatar(state) {
    return state.avatar;
  },
  // 获取用户头像
  introduction: function introduction(state) {
    return state.introduction;
  },
  // 获取用户简介
  createAt: function createAt(state) {
    return state.createAt;
  },
  // 创建时间
  folderCollect: function folderCollect(state) {
    return state.folderCollect;
  },
  //最近收藏过的文件夹
  // 账户信息
  accountData: function accountData(state) {
    return state.accountData;
  },
  // 设置信息
  accountSettingsData: function accountSettingsData(state) {
    return state.accountData ? state.accountData['settings'] || {} : {};
  }
};
var mutations = {
  updateLoginStatus: function updateLoginStatus(state, isUsed) {
    state.used = isUsed;
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_ADMIN: function SET_ADMIN(state, admin) {
    state.isAdmin = admin;
  },
  SET_TEMP_TOKEN: function SET_TEMP_TOKEN(state, tempToken) {
    state.tempToken = tempToken;
  },
  SET_USER_INFO: function SET_USER_INFO(state, userInfo) {
    state.userInfo = Object.assign({}, state.userInfo, userInfo);
  },
  SET_COMPANY_INFO: function SET_COMPANY_INFO(state, companyInfo) {
    state.companyInfo = Object.assign({}, state.companyInfo, companyInfo);
  },
  SET_USER_ID: function SET_USER_ID(state, userID) {
    state.userID = userID;
  },
  SET_STATUS: function SET_STATUS(state, status) {
    state.status = status;
  },
  SET_GUIDE_STEP_INDEX: function SET_GUIDE_STEP_INDEX(state, guideStepIndex) {
    state.guideStepIndex = guideStepIndex;
  },
  SET_NOW_SERVER_TIME: function SET_NOW_SERVER_TIME(state, time) {
    state.nowServerTime = time;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_REAL_NAME: function SET_REAL_NAME(state, realName) {
    state.real_name = realName;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  STATUS: function STATUS(state, status) {
    state.status = status;
  },
  CHANGE_AVATAR: function CHANGE_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  CHANGE_EMAIL: function CHANGE_EMAIL(state, email) {
    state.email = email;
  },
  CLEAR_USER_INFO: function CLEAR_USER_INFO(state) {
    state.token = '';
    state.userID = '';
    state.email = '';
    state.avatar = '';
    state.createAt = '';
    state.userInfo = {};
  },
  // 设置账户信息
  SET_ACCOUNT_DATA: function SET_ACCOUNT_DATA(state) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    state.accountData = data;
  },
  // 清空账户信息
  CLEAR_ACCOUNT_INFO: function CLEAR_ACCOUNT_INFO(state) {
    state.accountData = {};
  }
};
var actions = {
  setUserInfo: function setUserInfo(_ref, payload) {
    var commit = _ref.commit;
    // 存储用户信息
    var token = payload.token;
    if (token) {
      commit('SET_TOKEN', token);
    }
    commit('SET_USER_ID', (payload === null || payload === void 0 ? void 0 : payload.id) || (payload === null || payload === void 0 ? void 0 : payload.third_id));
    commit('SET_NAME', payload === null || payload === void 0 ? void 0 : payload.name);
    commit('SET_AVATAR', (payload === null || payload === void 0 ? void 0 : payload.head) || (payload === null || payload === void 0 ? void 0 : payload.avatar));
    commit('STATUS', payload === null || payload === void 0 ? void 0 : payload.status);
    commit('SET_USER_INFO', payload);
    commit('SET_COMPANY_INFO', payload === null || payload === void 0 ? void 0 : payload.ent);
  },
  setCompanyInfo: function setCompanyInfo(_ref2, info) {
    var commit = _ref2.commit;
    commit('SET_COMPANY_INFO', info);
  },
  logout: function logout(_ref3) {
    var commit = _ref3.commit;
    commit('CLEAR_USER_INFO');
    commit('CLEAR_ACCOUNT_INFO');
    // return logout().then(res => {
    //   commit('CLEAR_USER_INFO');
    //   commit('CLEAR_ACCOUNT_INFO');
    //   return res;
    // });
  },
  updateToken: function updateToken(_ref4, token) {
    var commit = _ref4.commit;
    commit('SET_TOKEN', token);
  },
  setNowServerTime: function setNowServerTime(_ref5, time) {
    var commit = _ref5.commit;
    commit('SET_NOW_SERVER_TIME', time);
  },
  setToken: function setToken(_ref6, token) {
    var commit = _ref6.commit;
    commit('SET_TOKEN', token);
  },
  setTempToken: function setTempToken(_ref7, tempToken) {
    var commit = _ref7.commit;
    commit('SET_TEMP_TOKEN', tempToken);
  },
  setName: function setName(_ref8, name) {
    var commit = _ref8.commit;
    commit('SET_NAME', name);
  },
  changeAvatar: function changeAvatar(_ref9, avatar) {
    var commit = _ref9.commit;
    commit('CHANGE_AVATAR', avatar);
  },
  // 获取账户信息
  getAccountInfo: function getAccountInfo(_ref10) {
    var commit = _ref10.commit,
      state = _ref10.state;
    if (!state.token) {
      return function () {};
    }
    return getMyAccount().then(function (res) {
      if (!res) {
        commit('SET_TOKEN', '');
        return res;
      }
      var info = res === null || res === void 0 ? void 0 : res.data;
      info.head = info === null || info === void 0 ? void 0 : info.avatar;
      commit('SET_ACCOUNT_DATA', info);
      commit('SET_COMPANY_INFO', info === null || info === void 0 ? void 0 : info.ent);
      commit('SET_USER_INFO', info);
      commit('STATUS', info === null || info === void 0 ? void 0 : info.status);
      commit('SET_ADMIN', (info === null || info === void 0 ? void 0 : info.role) === 'root');
      return info;
    });
  },
  // 清空账户信息
  clearAccountInfo: function clearAccountInfo(_ref11) {
    var commit = _ref11.commit;
    commit('CLEAR_ACCOUNT_INFO');
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};