export default {
  components: {},
  data: function data() {
    return {};
  },
  watch: {},
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};