export default {
  data: function data() {
    return {
      showIndex: 0,
      imageList: [],
      isShow: false
    };
  },
  methods: {
    show: function show() {
      this.isShow = true;
    },
    closeViewBigImage: function closeViewBigImage() {
      this.imageViewerUrl = [];
      this.isShow = false;
    }
  }
};