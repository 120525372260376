export default {
  props: {
    config: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      filterData: {},
      filterType: ['date', 'select']
    };
  },
  created: function created() {
    this.initFilter();
  },
  methods: {
    initFilter: function initFilter() {
      var _this = this;
      this.config.forEach(function (item) {
        var key = item.key,
          defaultVal = item.defaultVal;
        _this.$set(_this.filterData, key, defaultVal || '');
      });
    },
    search: function search() {
      this.$emit('search', this.paramsFilter(this.filterData));
    },
    changeForm: function changeForm() {
      this.$emit('change', this.paramsFilter(this.filterData));
    },
    paramsFilter: function paramsFilter(_params) {
      // 参数处理
      var params = JSON.parse(JSON.stringify(_params));
      params = this.dateHandle(params);
      for (var i in params) {
        if (typeof params[i] === 'string' && (params[i].includes(']') || params[i] === '0')) {
          params[i] = JSON.parse(params[i]);
        }
      }
      return params;
    },
    dateHandle: function dateHandle(params) {
      this.config.forEach(function (item) {
        var key = item.key,
          type = item.type;
        var val = params[key];
        if (type === 'date') {
          params[key] = Date.parse(params[key]);
        }
        if (type === 'datetimerange') {
          var keys = key.split(',');
          if (params[key]) {
            params.time_ms_range = {};
            params.time_ms_range[keys[0]] = Date.parse(params[key][0]) || null;
            params.time_ms_range[keys[1]] = Date.parse(params[key][1]) || null;
          }
          delete params[key];
        }
        if (val === '') {
          // delete params[key]
        }
      });
      return params;
    },
    resetParams: function resetParams() {
      this.initFilter();
      this.search();
    }
  }
};