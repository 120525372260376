import CTable from '@/components/CTable';
import { timestampToTime } from '@/utils/diffDate';
import { getOrderList } from '@/api/order';
import { COMBO_TYPE } from '@/utils/common';
export default {
  components: {
    CTable: CTable
  },
  data: function data() {
    var _this = this;
    var h = this.$createElement;
    return {
      config: {
        getDataFun: getOrderList,
        columns: [{
          label: 'Purchase2',
          key: 'id',
          width: '300'
        }, {
          label: 'Purchase3',
          key: 'order_sn',
          width: 150,
          render: function render(row) {
            var level = row.payload.level;
            return h("p", [_this.$t(COMBO_TYPE[level - 1])]);
          }
        }, {
          label: 'Purchase4',
          key: 'compute_amount',
          render: function render(row) {
            var act_price = row.payload.act_price;
            return h("p", ["\uFFE5", act_price]);
          }
        }, {
          label: 'Purchase5',
          key: 'gift_account_amount',
          render: function render(row) {
            var gift_account_amount = row.payload.gift_account_amount;
            return h("p", [gift_account_amount, _this.$t('Purchase23')]);
          }
        }, {
          label: 'Purchase6',
          key: 'duration',
          render: function render(row) {
            var gift_gpu = row.payload.gift_gpu;
            return h("p", [gift_gpu, _this.$t('Hours')]);
          }
        }, {
          label: 'Purchase7',
          key: 'gift_store',
          render: function render(row) {
            var gift_store = row.payload.gift_store;
            return h("p", [gift_store, "G"]);
          }
        }, {
          label: 'Purchase8',
          key: 'purchase_time',
          width: 170,
          render: function render(row) {
            return h("p", [timestampToTime(row.purchase_time) || '-']);
          }
        }]
      }
    };
  }
};