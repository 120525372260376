var getters = {
  isOpen: function isOpen(state) {
    return state.app.isOpen;
  },
  token: function token(state) {
    return state.user.token;
  },
  isSupportWebp: function isSupportWebp(state) {
    return state.app.isSupportWebp;
  }
};
export default getters;