import fetch from '@/utils/fetch';
var publicPath = '/api/v1/manager/payment/order/';

/**
获取相应时长的购买信息
*/
export function getPaymentInfo(data) {
  return fetch({
    url: publicPath + 'pay-price',
    method: 'post',
    data: data
  });
}
// 查询订单
export function getOrderStatus(data) {
  return fetch({
    url: publicPath + 'status',
    method: 'post',
    data: data
  });
}

// 发起订单
export function createOrder(data) {
  return fetch({
    url: publicPath + 'create',
    method: 'post',
    data: data
  });
}