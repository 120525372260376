import Clipboard from 'clipboard';

/**
 * 复制文字到剪贴板
 * @param text
 */

export function copyTextToClipboard(text) {
  var button = document.createElement('button');
  button.style.cursor = 'pointer';
  button.className = 'copyTextToClipboardBtn';
  button.id = 'copyTextToClipboardBtn';
  button.setAttribute('data-clipboard-text', text);
  document.body.appendChild(button);
  button.setAttribute('data-clipboard-text', text);
  new Clipboard('.copyTextToClipboardBtn');
  button.click();
  document.body.removeChild(button);

  // // 创建一个临时的 textarea 元素
  // const textarea = document.createElement('textarea');
  // textarea.value = text || '';
  // // 将 textarea 添加到文档中
  // document.body.appendChild(textarea);
  // // 选中 textarea 中的文本并复制到剪贴板中
  // textarea.select();
  // document.execCommand('copy');
  // // 移除临时元素
  // document.body.removeChild(textarea);
}

/**
 * 处理图片url, 返回相应质量的图片
 * @param imageUrl 图片地址
 * @param quality low, high, 默认为空
 */
export function replaceImageType(imageUrl) {
  var quality = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var REGEX_IMAGE_FORMAT = /.*\.(png|gif|bmp|tiff?|svg|ico)$/i;
  var match = imageUrl.match(REGEX_IMAGE_FORMAT);
  if (match) {
    var formatName = quality === 'high' ? '_high.webp' : quality === 'low' ? '_low.webp' : '';
    var name = imageUrl.replace(match[1], '').slice(0, -1);
    return formatName ? name + formatName : imageUrl;
  }
  return imageUrl;
}
/**
 * 检查浏览器是否支持webp
 * */
export function isSupportWebp() {
  try {
    return document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0;
  } catch (err) {
    return false;
  }
}

/**
 * 判断日期是否是同一天
 * */
export function isSameDay(date1, date2) {
  var result = false;
  try {
    var tempDate1 = new Date(date1).setHours(0, 0, 0, 0);
    var tempDate2 = new Date(date2).setHours(0, 0, 0, 0);
    result = tempDate1 === tempDate2;
  } catch (e) {}
  return result;
}
/**
 * 时间格式化到
 * */
export function timeFormat(num) {
  var min = parseInt(num / 60);
  var sec = num % 60;
  min = min < 10 ? '0' + min : min;
  sec = sec < 10 ? '0' + sec : sec;
  return min + ':' + sec;
}
/****
 * 单位转换大于10000转换为1万
 */
export function formatNumber(num) {
  if (!num) return 0;
  if (num < 1000) {
    return num.toString();
  } else if (num < 10000) {
    return Math.floor(num / 100) / 10 + 'k';
  } else {
    return Math.floor(num / 1000) / 10 + 'w';
  }
}
/****
 * 单位转换 for foreign
 */
export function formatNumberForeign(num) {
  if (!num) return 0;
  if (num < 1000) {
    return num.toString();
  } else if (num < 1000000) {
    return (Math.floor(num / 100) / 10).toFixed(num % 1000 === 0 ? 0 : 1) + 'K';
  } else if (num < 1000000000) {
    return (Math.floor(num / 100000) / 10).toFixed(num % 1000000 === 0 ? 0 : 1) + 'M';
  } else {
    return '999.9M';
  }
}

// 获取localStorage
export function getLocalStorage(key) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  try {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return defaultValue;
    }
  } catch (e) {
    return defaultValue;
  }
}

/**
 * 工具函数--转换宽度和高度
 * */
export function resizeToMaxDimension(width, height, maxDimension) {
  if (width > maxDimension) {
    var ratio = maxDimension / width;
    width = Math.round(width * ratio);
    height = Math.round(height * ratio);
  }
  if (height > maxDimension) {
    var _ratio = maxDimension / height;
    width = Math.round(width * _ratio);
    height = Math.round(height * _ratio);
  }
  return {
    width: width,
    height: height
  };
}

// 上传图片之前，对本地上传的图片做一下预处理
export function preprocessingImages(file) {
  return new Promise(function (resolve, reject) {
    var reader = new FileReader();
    reader.addEventListener('load', function () {
      var image = new Image();
      image.addEventListener('load', function () {
        // 创建一个 canvas 元素
        var canvas = document.createElement('canvas');
        // 转换一下图片宽高，以最长的一边来转
        var result = resizeToMaxDimension(image.width, image.height, 1024);

        // 将 canvas 大小设置为图片大小
        canvas.width = result.width;
        canvas.height = result.height;

        // 获取 canvas 的上下文对象
        var ctx = canvas.getContext('2d');

        // 在 canvas 上绘制图片
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        // 将 canvas 转换为 PNG 格式的 base64 编码
        var pngData = canvas.toDataURL('image/png', 1);

        // 将 base64 编码转换为 Blob 对象
        var blob = dataURItoBlob(pngData);
        // 创建一个新的 File 对象
        var pngFile = new File([blob], file.name.replace(/\.\w+$/, '.png'), {
          type: 'image/png'
        });
        resolve(pngFile);
      });
      image.src = reader.result;
    });
    reader.addEventListener('error', function () {
      console.log('Failed to read the file.');
      // 读取文件失败
      reject(new Error('Failed to read the file.'));
    });
    reader.readAsDataURL(file);
  }).catch(function (error) {
    return Promise.reject(new Error('Failed to convert the image to PNG format.'));
  });
}

/**
 * 预签名上传图片的方法
 * */
export function preSignUpload() {
  var fileItem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new Promise(function (resolve, reject) {
    var file_name = fileItem.file_name,
      file_id = fileItem.file_id,
      file = fileItem.file,
      uploadPath = fileItem.uploadPath;
    var fileType = file_name.substring(file_name.lastIndexOf('.') + 1).toLowerCase();
    var fileName = file_id + '.' + fileType;
    var formData = new FormData();
    formData.append('file', file, fileName);
    // 1. 创建 xhr 对象
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.responseText ? JSON.parse(xhr.responseText) : null);
      } else {
        reject(new Error(xhr.statusText));
      }
    };
    xhr.onerror = function (e) {
      reject(e);
    };
    // 2. 调用 open 函数，指定请求类型与URL地址。其中，请求类型必须为 post
    xhr.open('PUT', uploadPath);
    xhr.setRequestHeader('Content-Type', 'image/' + fileType);
    // 3. 发起请求
    xhr.send(file);
  });
}

/**
 * 工具函数--将 base64 图片转换为 Blob 对象
 * */
export function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {
    type: mimeString
  });
}