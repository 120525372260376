var render = function render() {
  var _vm$paymentData, _vm$paymentData2, _vm$paymentData3;
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "payment-status-dialog",
    attrs: {
      top: "calc(50vh - 168px)",
      width: "416px",
      visible: _vm.isShowDialog,
      "destroy-on-close": true,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.isShowDialog = $event;
      },
      closed: _vm.close
    }
  }, [_c("div", {
    staticClass: "container",
    style: _vm.getContainerStyle
  }, [!_vm.isLoading ? _c("img", {
    staticClass: "icon",
    attrs: {
      src: require("@/assets/images/payment-status-success.png"),
      alt: "success icon"
    }
  }) : _vm._e(), _vm._v(" "), _vm.isLoading ? _c("div", {
    staticClass: "loading"
  }, [_c("div", {
    staticClass: "lds-ring"
  }, [_c("div"), _vm._v(" "), _c("div"), _vm._v(" "), _c("div"), _vm._v(" "), _c("div")]), _vm._v(" "), _c("div", {
    staticClass: "words"
  }, [_vm._v("\n        " + _vm._s(_vm.$t("Renewal38")) + "\n      ")]), _vm._v(" "), _c("el-button", {
    staticClass: "cancel-btn",
    on: {
      click: function click($event) {
        _vm.isShowDialog = false;
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t("Cancel")) + "\n      ")])], 1) : _c("div", {
    staticClass: "finish"
  }, [_c("div", {
    staticClass: "tip"
  }, [_vm._v(_vm._s(_vm.$t("Renewal39")) + " !")]), _vm._v(" "), _c("div", {
    staticClass: "amount"
  }, [_vm._v("\n        " + _vm._s("".concat(_vm.$CHINA ? "￥" : "$", " ").concat((_vm$paymentData = _vm.paymentData) === null || _vm$paymentData === void 0 ? void 0 : _vm$paymentData.price)) + "\n      ")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "item-content purchase-detail"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("Renewal32")) + ":")]), _vm._v(" "), _c("div", {
    staticClass: "detail"
  }, [_c("div", {
    staticClass: "vipcard detail-div"
  }, [_vm._v("\n              " + _vm._s(((_vm$paymentData2 = _vm.paymentData) === null || _vm$paymentData2 === void 0 ? void 0 : _vm$paymentData2.product_name) || _vm.$t("Renewal40")) + "\n\n            ")])])]), _vm._v(" "), _c("div", {
    staticClass: "item-content order-number"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("Renewal41")) + ":")]), _vm._v(" "), _c("div", {
    staticClass: "detail"
  }, [_vm._v("\n            " + _vm._s((_vm$paymentData3 = _vm.paymentData) === null || _vm$paymentData3 === void 0 ? void 0 : _vm$paymentData3.order_no) + "\n          ")])]), _vm._v(" "), _c("div", {
    staticClass: "item-content payment-time"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$t("Renewal42")) + ":")]), _vm._v(" "), _c("div", {
    staticClass: "detail"
  }, [_vm._v("\n            " + _vm._s(_vm.getExpiryTime) + "\n          ")])])]), _vm._v(" "), _c("el-button", {
    staticClass: "done-btn",
    on: {
      click: _vm.handleDone
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t("Renewal43")) + "\n      ")])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };