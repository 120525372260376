import { render, staticRenderFns } from "./SetUserPopup.vue?vue&type=template&id=0f878be2&scoped=true&"
import script from "./SetUserPopup.vue?vue&type=script&lang=js&"
export * from "./SetUserPopup.vue?vue&type=script&lang=js&"
import style0 from "./SetUserPopup.vue?vue&type=style&index=0&id=0f878be2&lang=scss&scoped=true&"
import style1 from "./SetUserPopup.vue?vue&type=style&index=1&id=0f878be2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f878be2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/s01-aiart-release/s01_aiart_release_aiart-web-openapi/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0f878be2')) {
      api.createRecord('0f878be2', component.options)
    } else {
      api.reload('0f878be2', component.options)
    }
    module.hot.accept("./SetUserPopup.vue?vue&type=template&id=0f878be2&scoped=true&", function () {
      api.rerender('0f878be2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SetUserPopup.vue"
export default component.exports